<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>PPE Requests</h1>
              </div>
              <div class="row">
                <div class="col-md-8 d-flex justify-start">
                  <div class="row">
                    <div class="col-md-4">
                      <!-- <TextField type="date" :label="'From'" /> -->
                    </div>
                    <div class="col-md-4">
                     <!-- <TextField type="date" :label="'to'" /> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="export-container">
                    <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                    <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="mr-2"/>
                  </div>
                </div>
              </div>
             <v-data-table
              :headers= 'headers'
              :items="ppeRecords"
             >
             </v-data-table>
             <div v-if="loadingData" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
             </div>
             <table hidden id="ppeRequestTable">
                <thead>
                    <tr>
                      <th>Personnel Name</th>
                      <th>Location</th>
                      <th>Job Title</th>
                      <th>Employer</th>
                      <th>PPE Items</th>
                      <th>Request Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ppeRecords" :key="item.requestId">
                      <td>
                        {{item.personnelName}}
                      </td>
                      <td>
                        {{item.location}}
                      </td>
                      <td>
                        {{item.jobTitle}}
                      </td>
                      <td>
                        {{item.contractEmployerName}}
                      </td>
                      <td>
                        {{item.items}}
                      </td>
                      <td>
                        {{item.date}}
                      </td>
                      <td>
                        {{item.status}}
                      </td>
                    </tr>
                  </tbody>
              </table>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import SelectField from '@/components/ui/form/SelectField.vue'
// import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { requestService } from '@/services'
import ExportToCSVFile from '@/utils/helper'
import moment from 'moment'
 /*import XLSX from 'xlsx'*/
import * as XLSX from 'xlsx/xlsx.mjs';
import JSPDF from 'jspdf'
import 'jspdf-autotable'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog,
    SelectField
    // TextField
  },
  data () {
    return {
      search: '',
      isDownloading: false,
      loadingData: false,
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      ppeRecords: [],
      excelExportRecords: [],
      headers: [
        {
          text: 'Personnel Name',
          value: 'personnelName'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Job Title',
          value: 'jobTitle'
        },
        {
          text: 'Employer',
          value: 'contractEmployerName'
        },
        {
          text: 'PPE Items',
          value: 'items'
        },
        {
          text: 'Request Date',
          value: 'date'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      downloadFormat: [
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'PDF',
          id: 'pdf'
        },
        {
          name: 'CSV',
          id: 'csv'
        }
      ],
      selectedFormat: ''
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      // this.$router.push('/medical/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    getApprovedPPEItems () {
      this.loadingData = true
      requestService.getApprovedPPE().then(result => {
        const data = result.data
        const records = []
        const excelRecords = []

        data.forEach(item => {
          const ppeItems = item.ppEs
          let requestItems = ''

          if (ppeItems.length > 0) {
            ppeItems.forEach(ppe => {
              if (requestItems !== '') requestItems += ', '

              requestItems += `${ppe.ppeType.name} (${ppe.narration})`
              const excelItem = {
                'Personnel Name': item.employeeName,
                'Contract Employer': item.contractEmployerName,
                'Request Date': moment(item.requestDate).format('DD-MMM-yyyy'),
                'Status': item.status,
                'Location': item.location,
                'Job Title': item.jobTitle,
                'Item Name': ppe.ppeType.name,
                'Item Count': ppe.narration
              }

              excelRecords.push(excelItem)
            })
          } else {
            const excelItem = {
              'Personnel Name': item.employeeName,
              'Contract Employer': item.contractEmployerName,
              'Request Date': moment(item.requestDate).format('DD-MMM-yyyy'),
              'Status': item.status,
              'Location': item.location,
              'Job Title': item.jobTitle,
              'Item Name': '',
              'Item Count': 0
            }

            excelRecords.push(excelItem)
          }

          records.push({
            personnelName: item.employeeName,
            contractEmployerName: item.contractEmployerName,
            date: moment(item.requestDate).format('DD-MMM-yyyy'),
            status: item.status,
            location: item.location,
            jobTitle: item.jobTitle,
            items: requestItems
          })
        })

        this.ppeRecords = records
        this.excelExportRecords = excelRecords
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loadingData = false
      })
    },
    onDownload () {
      if (this.selectedFormat !== '') {
        try {
          this.isDownloading = true
          const time = moment().format()
          switch (this.selectedFormat) {
            case 'pdf':
              const doc = new JSPDF('landscape')
              doc.autoTable({
                theme: 'striped',
                headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
                alternateRowStyles: { fillColor: '#F3F4F8' },
                html: '#ppeRequestTable'
              })

              doc.text('PPE Requests - CMMS', 150, 10, { align: 'center' })
              // doc.text('L - Leave', 150, 200, { align: 'center' })
              doc.save(`ApprovedPPE_${time}.pdf`)
              this.isDownloading = false
              break
            case 'excel':
              var ppeSheet = XLSX.utils.json_to_sheet(this.excelExportRecords)
              var ppeWorkBook = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(ppeWorkBook, ppeSheet, 'ApprovedPpe')
              XLSX.writeFile(ppeWorkBook, `PpeRequest${time}.xlsx`)
              this.isDownloading = false
              break
            case 'csv':
              const formattedData = []
              const fileHeaders = {
                personnelName: 'Personnel Name',
                location: 'Location',
                jobTitle: 'Job Title',
                contractEmployerName: 'Employer',
                itemName: 'Item Name',
                itemCount: 'Item Count',
                date: 'Request Date',
                status: 'Status'
              }

              this.excelExportRecords.forEach(item => {
                formattedData.push({
                  personnelName: item['Personnel Name'].replace(/,/g, ''),
                  location: item['Location'].replace(/,/g, ''),
                  jobTitle: item['Job Title'].replace(/,/g, ''),
                  contractEmployerName: item['Contract Employer'].replace(/,/g, ''),
                  itemName: item['Item Name'].replace(/,/g, ''),
                  itemCount: item['Item Count'],
                  date: item['Request Date'],
                  status: item['Status']
                })
              })

              ExportToCSVFile(fileHeaders, formattedData, `PpeRequest_${time}`)
              this.isDownloading = false
              break
            default:
              break
          }
        } catch (error) {
          console.error(error)
          this.isDownloading = false
        }
      }
    }
  },
  mounted () {
    this.getApprovedPPEItems()
  }
}
</script>
<style scoped>
.export-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
